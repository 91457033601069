@import "variables";
@import "bootswatch";
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "../../../node_modules/highlightjs/styles/github.css";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/brands";

small {
    display: block;
    color: #888;
}

footer.container {
    margin-top: 30px;
    padding: 30px 0;
    border-top: 1px dashed #aaa;
}

@media (max-width: 767px) {
    .sidebar {
        padding-top: 30px;
    }
}

@media (min-width: 980px) {
    body {
        padding-bottom: 40px;
    }
}

body > main.container, body > footer.container {
    padding: 80px 15px 0; /* 80px to make the container go all the way to the bottom of the topbar */
}

.tags {
    border: 0 solid $light;
    border-top-width: 2px;
    padding-top: $spacer;
}

article {
    padding: $spacer * 1.5;
    margin-bottom: $spacer * 1.5;
    background-color: $white;
}

nav {
    background-repeat: no-repeat;
    background-position: top right;
    background-image: url("/assets/icons/70s-brown.png");
}

code {
    color: $lightorange
}

.card {
    border-width: 0;
}

header .text-muted {
    font-size: 80%;
}

.featured-img {
    overflow: hidden;
}

.featured-img img {
    z-index: 1;
    height: 200px;
    width: 100%;
    object-fit: cover;
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .featured-img img {
            height: map-get(("-sm": 120px, "-md": 120px, "-lg": 148px, "-xl": 178px), $infix);
            width: map-get(("-sm": 120px, "-md": 120px, "-lg": 148px, "-xl": 178px), $infix);
        }
    }
}
